$(document).ready(function () {  
  $.nette.init();
  
  $(".sign-toggle").click(function (e) {
    e.preventDefault();
    $(".sign").slideToggle(1000);
  }); 
  
  //autohide alerts
  window.setTimeout(function() {
    $(".alert").slideUp(500, function(){
      $(this).remove(); 
    });
  }, 4000);
});